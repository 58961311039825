import axios from "axios";
import { resetFailer, resetStart, resetSuccess } from "./resetReducer";
import { GLOBAL_URL } from "../global-url/global-url";

export const resetThunk = (props) => {
  const { password, uidb64, token } = props;

  let formData = new FormData();
  formData.append("password", password);
  formData.append("token", token);
  formData.append("uidb64", uidb64);

  return async (dispatch) => {
    try {
      dispatch(resetStart());

      const res = await axios.patch(
        `${GLOBAL_URL}/api/v1/user/password-reset-complete/`,
        formData
      );
      dispatch(resetSuccess(res.data));
    } catch (e) {
      dispatch(resetFailer(e.message));
    }
  };
};
