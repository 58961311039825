import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  student: null,
  error: null,
};

export const studentReducer = createSlice({
  name: "student",
  initialState,
  reducers: {
    studentStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    studentSuccess: (state, action) => ({
      ...state,
      student: action.payload,
      isLoading: false,
      error: null,
    }),
    studentFailure: (state, action) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
  },
});

export const { studentStart, studentSuccess, studentFailure } =
  studentReducer.actions;
export default studentReducer.reducer;
