import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const resetReducer = createSlice({
  name: "reset",
  initialState,
  reducers: {
    resetStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    resetSuccess: (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
    }),
    resetFailer: (state, action) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
  },
});

export const { resetStart, resetSuccess, resetFailer } = resetReducer.actions;
export default resetReducer.reducer;
