import axios from "axios";
import { loginFailer, loginStart, loginSuccess } from "./authReducer";
import Cookies from "js-cookie";
import { GLOBAL_URL } from "../global-url/global-url";

export const loginUser = (data) => async (dispatch) => {
  const { email, password } = data;
  try {
    dispatch(loginStart());
    const res = await axios.post(`${GLOBAL_URL}/api/v1/user/token/`, {
      email,
      password,
    });
    const token = res.data.access;

    Cookies.set("token", token, { expires: 5 });
    dispatch(loginSuccess(token));
  } catch (e) {
    dispatch(loginFailer(e.message));
  }
};
