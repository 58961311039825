import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  authData: {
    accessToken: null,
    isLoading: false,
    error: null,
  },
};

export const authReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginStart: (state) => ({
      ...state,
      authData: {
        ...state.authData,
        isLoading: true,
      },
    }),
    loginSuccess: (state, action) => ({
      ...state,
      authData: {
        ...state.authData,
        accessToken: action.payload,
        isLoading: false,
        error: null,
      },
    }),
    loginFailer: (state, action) => ({
      ...state,
      authData: {
        isLoading: false,
        error: action.payload,
      },
    }),
    logoutSuccess: (state) => ({
      ...state,
      authData: {
        accessToken: null,
        isLoading: false,
        error: null,
      },
    }),
  },
});

export const { loginStart, loginSuccess, loginFailer, logoutSuccess } =
  authReducer.actions;
export default authReducer.reducer;
