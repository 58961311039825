import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: null,
  isLoading: false,
  error: null,
};

export const registerReducer = createSlice({
  name: "register",
  initialState,
  reducers: {
    registerStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    registerSuccess: (state, action) => ({
      ...state,
      email: action.payload,
      isLoading: false,
    }),
    registerFailer: (state, action) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    logoutSuccessRegister: (state) => ({
      ...state,
      email: null,
      isLoading: false,
      error: null,
    }),
  },
});

export const {
  registerStart,
  registerSuccess,
  registerFailer,
  logoutSuccessRegister,
} = registerReducer.actions;
export default registerReducer.reducer;
