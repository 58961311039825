import { Card, CardBody } from "reactstrap";
import { ProfileSkillsItem } from "./porfile-skills-item";

export const ProfileSkills = ({ passed_courses }) => {
  return (
    <div className="w-[100%] pb-[20px]">
      <Card className="shadow-2xl">
        <CardBody>
          <h5 className="">Пройдені курси та сертифікати</h5>
          <div className="flex flex-row justify-center">
            {passed_courses &&
              (passed_courses.length == 0 ? (
                <div className="">
                  <span className="text-gray-400">
                    Ви ще не пройшли жодного курсу
                  </span>
                </div>
              ) : (
                passed_courses.map((item) => (
                  <ProfileSkillsItem
                    course={item.course}
                    key={item.course.id}
                  />
                ))
              ))}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
