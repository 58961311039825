import { NavLink } from "react-router-dom";

export const MyLessonsDropdown = ({ lessonTitle, moduleID, lessonID }) => {
  const title = lessonTitle.substring(0, 8);
  const number = 1;
  return (
    <>
      <NavLink
        to={`/lessonsData/${moduleID}/${lessonID}/${number}`}
        className="no-underline"
      >
        <div className="flex flex-col pl-[10px]">
          <span>- {title}</span>
        </div>
      </NavLink>
    </>
  );
};
