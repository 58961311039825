import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Spin } from "antd";
import { Breadcrumb } from "antd";
import { NavLink } from "react-router-dom";
import { RightCircleOutlined, LeftCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { CourseCompletion } from "./course-completion";
import Cookies from "js-cookie";
import { fetchData } from "../../slice/courses/fetchData";
import { studentThunk } from "../../slice/student/studentThunk";
import { boughtCoursesThunk } from "../../slice/my-courses/boughtCourses/boughtCoursesThunk";

export const LessonsDataModule = () => {
  const { id } = useParams();

  const [currentLesson, setCurrentLesson] = useState(0);
  const [myLessons, setMyLessons] = useState(null);
  const [courseID, setCourseID] = useState();

  const { isLoading, myBoughtCourses, error } = useSelector(
    (state) => state.boughtCoursesReducer
  );

  const myCourses = useSelector((state) => state.coursesReducer.myCourses);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  useEffect(() => {
    if (token) {
      dispatch(fetchData());
      dispatch(studentThunk());
    }
    if (!token) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (myCourses) {
        const { my_courses } = myCourses;
        dispatch(boughtCoursesThunk({ my_courses }));
      }
    }, 1000);
  }, [myCourses]);

  useEffect(() => {
    if (myBoughtCourses) {
      myBoughtCourses.map((item) => {
        item.course.modules.map((el) => {
          if (el.module.id == id) {
            setCourseID(item.id);
            setMyLessons(el.module.lessons);
          }
        });
      });
    }
  }, [myBoughtCourses]);

  const handlePrevLesson = () => {
    setCurrentLesson((prevLesson) => (prevLesson > 0 ? prevLesson - 1 : 0));
  };

  const handleNextLesson = () => {
    setCurrentLesson((prevLesson) =>
      prevLesson < myLessons.length - 1 ? prevLesson + 1 : prevLesson
    );
  };

  return (
    <div className="flex flex-col w-[100%]">
      <div>
        <div className="pl-[10px]">
          <Breadcrumb
            items={[
              {
                title: (
                  <NavLink to="/myCourses" className="no-underline">
                    Мої курси
                  </NavLink>
                ),
              },
              {
                title: (
                  <span>
                    {myLessons ? myLessons[currentLesson].lesson.title : ""}
                  </span>
                ),
              },
            ]}
          />
        </div>
        <hr />
      </div>
      {isLoading && (
        <div className="flex justify-center w-[100%] pt-[10%] pb-[20px]">
          <Spin size="large" />
        </div>
      )}
      {myLessons && (
        <div className="flex flex-col w-[100%] pt-[10px]">
          <div className="flex flex-row justify-between">
            <button
              onClick={handlePrevLesson}
              disabled={currentLesson === 0}
              className="pl-[20px] text-3xl "
            >
              <LeftCircleOutlined />
            </button>
            <button
              onClick={handleNextLesson}
              disabled={currentLesson === myLessons.length - 1}
              className="pr-[20px] text-3xl "
            >
              <RightCircleOutlined />
            </button>
          </div>
          <div>
            <CourseCompletion
              courseID={courseID}
              lessonID={myLessons[currentLesson].id}
              id={myLessons[currentLesson].lesson.id}
              text={myLessons[currentLesson].lesson.description}
              material={myLessons[currentLesson].lesson.material}
              title={myLessons[currentLesson].lesson.title}
              video={myLessons[currentLesson].lesson.video}
              questions={myLessons[currentLesson].lesson.questions}
              passed={myLessons[currentLesson].passed}
              note={myLessons[currentLesson].note}
              key={myLessons[currentLesson].lesson.id}
            />
          </div>
        </div>
      )}
      {error && (
        <div className="flex justify-center w-[100%] pt-[10%]">
          <h4>{error}</h4>
        </div>
      )}
    </div>
  );
};
