import axios from "axios";
import Cookies from "js-cookie";
import { noticeFailure, noticeStart, noticeSuccess } from "./noticeReducer";
import { GLOBAL_URL } from "../global-url/global-url";

export const readedThunk = (props) => {
  const token = Cookies.get("token");

  return async (dispatch) => {
    try {
      dispatch(noticeStart());
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      for (const id of props) {
        const res = await axios.get(
          `${GLOBAL_URL}/api/v1/commun/news/${id}/mark_as_read/`,
          {
            headers,
          }
        );
        dispatch(noticeSuccess([res.data]));
      }
    } catch (e) {
      dispatch(noticeFailure(e.message));
    }
  };
};
