import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  myCourses: null,
  error: null,
};

export const coursesReducer = createSlice({
  name: "courses",
  initialState,
  reducers: {
    coursesStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    coursesSuccess: (state, action) => ({
      ...state,
      myCourses: action.payload,
      isLoading: false,
      error: null,
    }),
    coursesFailure: (state, action) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
  },
});

export const { coursesSuccess, coursesStart, coursesFailure } =
  coursesReducer.actions;
export default coursesReducer.reducer;
