import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  myBoughtCourses: null,
  error: null,
};

export const boughtCourseReducer = createSlice({
  name: "courseModule",
  initialState,
  reducers: {
    boughtCourseStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    boughtCourseSuccess: (state, action) => ({
      ...state,
      myBoughtCourses: action.payload,
      isLoading: false,
      error: null,
    }),
    boughtCourseFailure: (state, action) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
  },
});

export const { boughtCourseStart, boughtCourseSuccess, boughtCourseFailure } =
  boughtCourseReducer.actions;
export default boughtCourseReducer.reducer;
