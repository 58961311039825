import React, { useRef, useState, useEffect, useCallback } from "react";
import { ProfileSkills } from "../../components/for-profile/profile-skills/profile-skills";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Spin } from "antd";
import { Card, CardBody } from "reactstrap";
import user from "../../assets/user.png";
import { CopyOutlined } from "@ant-design/icons";
import { changeDataThunk } from "../../slice/student/changeDataThunk";
import { useDropzone } from "react-dropzone";
import { changeImageThunk } from "../../slice/student/changeImageThunk";
import Cookies from "js-cookie";
import { fetchData } from "../../slice/courses/fetchData";
import { studentThunk } from "../../slice/student/studentThunk";
import "./styles/profile.css";
import { useNavigate } from "react-router";
import { message } from "antd";
import { ImageCropDialog } from "../../components/crop/image-crop-dialog";

const Profile = () => {
  const [selectedImage, setSelectedImage] = useState();
  const [selectedImageCrop, setSelectedImageCrop] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [telegram, setTelegram] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [clickButton, setClickButton] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  const { isLoading, student, error } = useSelector(
    (state) => state.studentReducer
  );

  const changedImage = useSelector((state) => state.imageReducer.image);

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Скопійовано!",
    });
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setSelectedImage(file);
    setSelectedImageCrop(file);
  };

  const navigate = useNavigate();

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const dispatch = useDispatch();

  const codeRef = useRef(null);

  const token = Cookies.get("token");

  useEffect(() => {
    if (token) {
      dispatch(fetchData());
      dispatch(studentThunk());
    }
    if (!token) {
      navigate("/");
    }
  }, []);

  const copyCode = (e) => {
    e.preventDefault();
    codeRef.current.select();
    document.execCommand("copy");
  };

  const sendDataUser = (id) => {
    let datas = {
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      telegram: telegram,
    };

    dispatch(changeDataThunk({ datas, id }));

    setTimeout(() => {
      setShowAlert(true);
    }, 500);
  };

  useEffect(() => {
    if (student) {
      student.map(
        (data) => (
          setFirstName(data.first_name),
          setLastName(data.last_name),
          setPhoneNumber(data.phone_number),
          setTelegram(data.telegram)
        )
      );
    }
  }, [student]);

  const handleFirstName = (e) => {
    if (e.target.value !== "") {
      setFirstName(e.target.value);
      setClickButton(false);
    } else {
      setClickButton(true);
    }
  };

  const handleLastName = (e) => {
    if (e.target.value !== "") {
      setLastName(e.target.value);
      setClickButton(false);
    } else {
      setClickButton(true);
    }
  };

  const handleNumber = (e) => {
    if (e.target.value !== "") {
      setPhoneNumber(e.target.value);
      setClickButton(false);
    } else {
      setClickButton(true);
    }
  };

  const handleTelegram = (e) => {
    if (e.target.value !== "") {
      setTelegram(e.target.value);
      setClickButton(false);
    } else {
      setClickButton(true);
    }
  };

  const handleSelect = () => {
    if (selectedImage) {
      setClickButton(false);
    } else {
      setClickButton(true);
    }
  };

  const onCansel = () => {
    setSelectedImageCrop(null);
  };

  const setCroppedImageFor = async (croppedImageUrl, id) => {
    const blob = croppedImageUrl;
    const fileName = "ava-native.png";
    const fileType = "image/png";

    const file = new File([blob], fileName, { type: fileType });

    let newImage = {
      image: file,
    };

    dispatch(changeImageThunk({ newImage, id }));

    setSelectedImageCrop(null);
    setShowAlert(true);
  };

  return (
    <div className="w-[100%] flex justify-center">
      <div className="profile">
        {isLoading && (
          <div className="flex justify-center w-[100%] pt-[10%] pb-[20px]">
            <Spin size="large" />
          </div>
        )}

        {showAlert ? (
          <div className="pb-[10px]">
            <Alert
              message="Дані успішно змінено!"
              type="success"
              showIcon
              action={
                <Button
                  onClick={() => setShowAlert(false)}
                  size="small"
                  type="text"
                >
                  закрити
                </Button>
              }
            />
          </div>
        ) : (
          <></>
        )}
        {contextHolder}
        {student ? (
          student?.map((item, i) => (
            <div className="w-[100%]">
              <Card className="shadow-2xl" key={item.id}>
                <CardBody>
                  <div>
                    <div className="flex flex-row">
                      <div className="avatar-lg w-[105px] h-[105px] pt-[10px]">
                        {changedImage ? (
                          <img
                            src={
                              changedImage.image
                                ? changedImage.image
                                : item.image || user
                            }
                            alt="user-img"
                            className="img-thumbnail rounded-circle"
                            style={{ width: "100px", height: "100px" }}
                          />
                        ) : (
                          <img
                            src={item.image ? item.image : user}
                            alt="user-img"
                            className="img-thumbnail rounded-circle"
                            style={{ width: "100px", height: "100px" }}
                          />
                        )}
                      </div>
                      <div className="flex flex-col text-start pl-[20px] pt-[30px]">
                        <span className="text-2xl font-medium">
                          {item.first_name} {item.last_name}
                        </span>
                        <span className="text-stone-400">
                          {item.student_data.email}
                        </span>
                        <span className="text-stone-400">
                          {item.student_data.role}
                        </span>
                        <div className="pt-[10px]" {...getRootProps()}>
                          <form encType="multipart/form-data">
                            <input {...getInputProps()} />
                            <div className="choice-for-desktop">
                              <Button
                                type="primary"
                                className="bg-violet-700"
                                onClick={handleSelect}
                              >
                                Вибір фото
                              </Button>
                              {/* <span className="pl-[10px] text-violet-700 overflow-hidden whitespace-nowrap overflow-ellipsis max-w-[110px]">
                                {selectedImage ? selectedImage.name : ""}
                              </span> */}
                            </div>
                            <div className="choice-for-mobile">
                              <Button
                                type="primary"
                                className="bg-violet-700"
                                onClick={handleSelect}
                              >
                                Вибір фото
                              </Button>
                              <span className="pl-[10px] text-violet-700 overflow-hidden whitespace-nowrap overflow-ellipsis max-w-[180px]">
                                {selectedImage ? selectedImage.name : ""}
                              </span>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="pt-[20px]">
                      <div className="pt-[20px]">
                        <span className="text-3xl">Персональні дані</span>
                      </div>
                      <div className="for-center">
                        <form className="form">
                          <div className="profile-first-name">
                            <div className="flex flex-row ">
                              <label className="pr-[52px]">Імʼя</label>
                              <input
                                type="text"
                                placeholder={item.first_name}
                                className="2xl:w-[300px] md:w-[250px] bg-sky-100"
                                onChange={(e) => handleFirstName(e)}
                              />
                            </div>
                            <div className="between"></div>
                            <div className="flex flex-row">
                              <label className="">Реферальний код</label>
                              <input
                                ref={codeRef}
                                value={item.student_data.referral_code}
                                className="referal-link"
                                readOnly
                              />
                              <button
                                onClick={(e) => {
                                  copyCode(e), success();
                                }}
                                className="pl-[5px]"
                              >
                                <CopyOutlined />
                              </button>
                            </div>
                          </div>
                          <div>
                            {selectedImageCrop ? (
                              <div>
                                <ImageCropDialog
                                  imageUrl={selectedImage}
                                  cropInit={selectedImage.crop}
                                  zoomInit={selectedImage.zoom}
                                  aspectInit={selectedImage.aspect}
                                  id={item.id}
                                  onCansel={onCansel}
                                  setCroppedImageFor={setCroppedImageFor}
                                />
                              </div>
                            ) : null}
                          </div>
                          <div className="flex flex-row justify-start pb-[20px]">
                            <label>Прізвище</label>
                            <input
                              type="text"
                              placeholder={item.last_name}
                              className="ml-[5px] 2xl:w-[300px] md:w-[250px] bg-sky-100"
                              onChange={(e) => handleLastName(e)}
                            />
                          </div>
                          <div className="flex flex-row justify-start pb-[20px]">
                            <label>Телефон</label>
                            <input
                              type="number"
                              placeholder={item.phone_number}
                              className="2xl:w-[300px] md:w-[250px] bg-sky-100 ml-[15px]"
                              onChange={(e) => handleNumber(e)}
                            />
                          </div>
                          <div className="flex flex-row justify-start pb-[20px]">
                            <label>Telegram</label>
                            <input
                              placeholder={item.telegram}
                              className="2xl:w-[300px] md:w-[250px]  bg-sky-100 ml-[11px]"
                              onChange={(e) => handleTelegram(e)}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                    <div>
                      <Button
                        onClick={() => sendDataUser(item.id)}
                        disabled={clickButton}
                        type="primary"
                        className="bg-violet-700"
                      >
                        Зберегти
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="pt-[20px]">
                <ProfileSkills
                  key={item.id}
                  passed_courses={item.passed_courses}
                />
              </div>
            </div>
          ))
        ) : (
          <>
            <div>
              <h1>{error}</h1>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Profile;
