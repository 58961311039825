import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { MyModulesDropdown } from "./my-modules-dropdown";

export const MyCourcesDropdown = (props) => {
  const {
    courcesTitle,
    courseModules,
    showMyModule,
    setShowMyModule,
    courseID,
  } = props;

  const handleMouseEnter = () => {
    setShowMyModule(true);
  };

  return (
    <div>
      <div className="flex flex-col pl-[10px]">
        <div
          className="flex flex-row cursor-pointer"
          onMouseEnter={handleMouseEnter}
        >
          <span className={showMyModule ? "text-violet-700" : "text-black"}>
            - {courcesTitle}
          </span>
          <div className="pl-[5px] text-xs pt-[3px]">
            {showMyModule ? <DownOutlined /> : <UpOutlined />}
          </div>
        </div>
        {showMyModule
          ? courseModules?.map((el) => (
              <MyModulesDropdown
                moduleID={el.module.id}
                courseID={courseID}
                modulesTitle={el.module.title}
                moduleTests={el.module.quizzes}
                modulesLessons={el.module.lessons}
                testsModule={el.module.quizzes}
                key={el.module.id}
              />
            ))
          : null}
      </div>
    </div>
  );
};
