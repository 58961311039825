import axios from "axios";
import {
  boughtCourseFailure,
  boughtCourseStart,
  boughtCourseSuccess,
} from "./boughtCourseReducer";
import Cookies from "js-cookie";

export const boughtCoursesThunk = ({ my_courses }) => {
  const token = Cookies.get("token");
  return async (dispatch) => {
    try {
      dispatch(boughtCourseStart());
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(my_courses, {
        headers,
      });
      dispatch(boughtCourseSuccess(res.data));
    } catch (e) {
      dispatch(boughtCourseFailure(e.message));
    }
  };
};
