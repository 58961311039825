import axios from "axios";
import { coursesFailure, coursesStart, coursesSuccess } from "./coursesReducer";
import Cookies from "js-cookie";
import { GLOBAL_URL } from "../global-url/global-url";

export const fetchData = () => {
  const token = Cookies.get("token");
  return async (dispatch) => {
    try {
      dispatch(coursesStart());
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(`${GLOBAL_URL}/api/v1/student/`, {
        headers,
      });
      dispatch(coursesSuccess(res.data));
    } catch (e) {
      dispatch(coursesFailure(e.message));
    }
  };
};
