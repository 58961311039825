import { Button } from "antd";
import error from "../../assets/error.png";
import { NavLink } from "react-router-dom";
import "./styles/not-found.css";

export const NotFound = () => {
  return (
    <div className="w-[100%] flex justify-center">
      <div className="width-error">
        <div>
          <img src={error} alt="" />
        </div>
        <div>
          <NavLink to="/">
            <Button type="primary" className="bg-sky-400">
              перейти на головну
            </Button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};
