import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const forgotReducer = createSlice({
  name: "forgot",
  initialState,
  reducers: {
    forgotStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    forgotSuccess: (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
    }),
    forgotFailer: (state, action) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
  },
});

export const { forgotStart, forgotSuccess, forgotFailer } =
  forgotReducer.actions;
export default forgotReducer.reducer;
