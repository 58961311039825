import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Card, CardBody } from "reactstrap";
import { answersClear } from "../../slice/answers/answersReducer";
import { resultThunk } from "../../slice/result/resultThunk";
import { useEffect } from "react";
import { useState } from "react";
import Cookies from "js-cookie";
import { fetchData } from "../../slice/courses/fetchData";
import { studentThunk } from "../../slice/student/studentThunk";
import { boughtCoursesThunk } from "../../slice/my-courses/boughtCourses/boughtCoursesThunk";
import "./styles/result-answer.css";

export const ResultAnswers = () => {
  const [courseID, setCourseID] = useState();
  const [moduleID, setModuleID] = useState();
  const [quizeID, setQuizeID] = useState();
  const [ID, setID] = useState();

  const result = useSelector((state) => state.answersReducer.answers);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const myCourses = useSelector((state) => state.coursesReducer.myCourses);

  const token = Cookies.get("token");

  useEffect(() => {
    if (token) {
      dispatch(fetchData());
      dispatch(studentThunk());
    }
    if (!token) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (myCourses) {
        const { my_courses } = myCourses;
        dispatch(boughtCoursesThunk({ my_courses }));
      }
    }, 1000);
  }, [myCourses]);

  const countTrue = result.reduce((count, value) => {
    if (value.getAnswer.is_correct === true) {
      return count + 1;
    }
    return count;
  }, 0);

  const countFalse = result.reduce((count, value) => {
    if (value.getAnswer.is_correct === false) {
      return count + 1;
    }
    return count;
  }, 0);

  const totalCount = result.length;
  const percentageTrue = (countTrue / totalCount) * 100;

  const roundedResult = percentageTrue.toFixed(1);

  const mainHandler = () => {
    dispatch(answersClear());
    navigate("/");
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  useEffect(() => {
    result?.map((item) => {
      setCourseID(item.courseID);
      setModuleID(item.moduleID);
      setQuizeID(item.questionID);
      setID(item.returnID);
    });
  }, [myCourses]);

  if (quizeID && moduleID && courseID && countTrue) {
    let datas = {
      result: countTrue,
      course: courseID,
      module: moduleID,
      quize: quizeID,
    };
    dispatch(resultThunk({ datas }));
  }

  return (
    <div className="w-[100%]">
      <div className="flex justify-center pt-[10%]">
        <Card className="size-answer">
          <CardBody>
            <div>
              {percentageTrue > 50 ? (
                <h1 className="text-green-500">Ви здали тест</h1>
              ) : (
                <h1 className="text-red-500">Ви не здали тест</h1>
              )}
            </div>
            <div className="flex flex-col pb-[10px]">
              <span>
                Всього питань:{" "}
                <span className="text-sky-600 font-medium">{totalCount}</span>{" "}
              </span>
              <span>
                Правильних відповідей:{" "}
                <span className="text-sky-600 font-medium">{countTrue}</span>
              </span>
              <span>
                Неправильних відповідей:{" "}
                <span className="text-sky-600 font-medium">{countFalse}</span>
              </span>
              <span>
                Відсоток правильних відповідей:{" "}
                <span className="text-sky-600 font-medium">
                  {roundedResult}%
                </span>
              </span>
            </div>
            <div>
              <Button onClick={mainHandler}>На головну</Button>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};
