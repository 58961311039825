import { NavLink } from "react-router-dom";

export const MyTestsDropdown = ({ moduleID, courseID, questionID }) => {
  return (
    <>
      <NavLink
        to={`/question/${courseID}/${moduleID}/${questionID}`}
        className="no-underline"
      >
        <div className="flex flex-col pl-[10px]">
          <span>- Тест</span>
        </div>
      </NavLink>
    </>
  );
};
