import React, { useEffect, useState } from "react";
import { BellOutlined } from "@ant-design/icons";
import { Badge, Dropdown, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { readedThunk } from "../../slice/notice/readedThunk";
import "./styles/notice.css";

export const Notice = () => {
  const dispatch = useDispatch();
  const [isReaded, setIsReaded] = useState();
  const [noticeID, setNoticeID] = useState();
  const { notice, error } = useSelector((state) => state.noticeReducer);

  useEffect(() => {
    let id = notice?.map((item) => item.id);
    setNoticeID(id);

    let readed = notice?.map((el) => el.is_readed);
    setIsReaded(readed);
  }, [notice]);

  const notRead = isReaded?.reduce((count, value) => {
    if (value === false) {
      return count + 1;
    }
    return count;
  }, 0);

  const handleClick = (e) => {
    e.preventDefault();
    dispatch(readedThunk(noticeID));
    setIsReaded([]);
  };

  return (
    <div>
      <Dropdown
        overlay={
          <div className="tw-dropdown-menu bg-white py-[5px] px-[5px] shadow-2xl rounded-lg ">
            <div className="scrollable-content">
              {notice?.map((item, index) => (
                <div key={index} className="">
                  {item.title}
                </div>
              ))}
            </div>
          </div>
        }
        overlayClassName="tw-dropdown-overlay" // Кастомный класс для оверлея
        placement="bottom"
        arrow
      >
        <div onMouseEnter={(e) => handleClick(e)}>
          <Space>
            <Badge count={notRead}>
              <BellOutlined style={{ fontSize: "30px" }} />
            </Badge>
          </Space>
        </div>
      </Dropdown>
    </div>
  );
};
