import axios from "axios";
import Cookies from "js-cookie";
import { GLOBAL_URL } from "../global-url/global-url";

export const resultThunk = (props) => {
  const { datas } = props;

  let formData = new FormData();
  formData.append("result", datas.result);
  formData.append("course", datas.course);
  formData.append("module", datas.module);
  formData.append("quize", datas.quize);

  const token = Cookies.get("token");

  return async (dispatch) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.post(
        `${GLOBAL_URL}/api/1/student/quize_result/`,
        formData,
        {
          headers,
        }
      );
    } catch (e) {}
  };
};
