import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  notice: null,
  error: null,
};

export const noticeReducer = createSlice({
  name: "notice",
  initialState,
  reducers: {
    noticeStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    noticeSuccess: (state, action) => ({
      ...state,
      notice: action.payload,
      isLoading: false,
      error: null,
    }),
    noticeFailure: (state, action) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
  },
});

export const { noticeStart, noticeSuccess, noticeFailure } =
  noticeReducer.actions;
export default noticeReducer.reducer;
