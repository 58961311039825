import axios from "axios";
import {
  registerFailer,
  registerStart,
  registerSuccess,
} from "./registerReducer";
import { GLOBAL_URL } from "../global-url/global-url";

export const RegisterUser = (data) => async (dispatch) => {
  const { email, password } = data;
  try {
    dispatch(registerStart());
    const res = await axios.post(`${GLOBAL_URL}/api/v1/user/register/`, {
      email,
      password,
    });
    dispatch(registerSuccess(res.data));
  } catch (e) {
    dispatch(registerFailer(e.message));
  }
};
