import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GLOBAL_URL } from "../global-url/global-url";

export const courseApi = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${GLOBAL_URL}/api/v1/`,
  }),
  endpoints: (build) => ({
    getCourse: build.query({
      query: () => `courses/courses/`,
    }),
  }),
});

export const { useGetCourseQuery } = courseApi;
