import { Document, Page } from "react-pdf";

export const CertificatesItem = (props) => {
  const { date, name, student, certificate, id } = props;

  return (
    <div className="flex flex-col" key={id}>
      <Document file={certificate}>
        <Page pageNumber={1} />
      </Document>
      <span className="2xl:text-2xl pl-[10px]  2xl:pt-[50px] md:text-xl md:pt-[50px] ">
        {name}
      </span>
      <span>{date}</span>
    </div>
  );
};
