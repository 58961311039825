export const ProfileSkillsItem = (props) => {
  const { course } = props;
  const { title, image } = course;
  return (
    <div className="flex flex-col px-[10px]">
      <img src={image} alt="" className="w-[80px]" />
      <span className="text-xs">{title}</span>
    </div>
  );
};
