import axios from "axios";
import { forgotFailer, forgotStart, forgotSuccess } from "./forgotReducer";

export const forgotPassword = (data) => async (dispatch) => {
  const { email } = data;
  try {
    dispatch(forgotStart());
    const res = await axios.post(
      `${GLOBAL_URL}/api/v1/user/request-reset-email/`,
      {
        email,
      }
    );
    dispatch(forgotSuccess(res.data));
  } catch (e) {
    dispatch(forgotFailer(e.message));
  }
};
