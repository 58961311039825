import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { QuestionData } from "./question-data";
import { Breadcrumb } from "antd";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import { fetchData } from "../../slice/courses/fetchData";
import { studentThunk } from "../../slice/student/studentThunk";
import { boughtCoursesThunk } from "../../slice/my-courses/boughtCourses/boughtCoursesThunk";
import { Timer } from "./timer";
import "./styles/question.css";

export const Question = () => {
  const { courseID, moduleID, questionID } = useParams();
  const [questions, setQuestions] = useState([]);
  let [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionsLength, setQuestionsLength] = useState([]);
  const [quizeTitle, setQuizeTitle] = useState(null);
  const [quizzesID, setQuizzesID] = useState(null);
  const [moduleIDForQuize, setModuleIDForQuize] = useState();

  const result = useSelector((state) => state.answersReducer.answers);

  const myBoughtCourses = useSelector(
    (state) => state.boughtCoursesReducer.myBoughtCourses
  );
  const myCourses = useSelector((state) => state.coursesReducer.myCourses);

  const dispatch = useDispatch();
  const token = Cookies.get("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      dispatch(fetchData());
      dispatch(studentThunk());
    }
    if (!token) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (myCourses) {
        const { my_courses } = myCourses;
        dispatch(boughtCoursesThunk({ my_courses }));
      }
    }, 1000);
  }, [myCourses]);

  useEffect(() => {
    myBoughtCourses?.map((item) => {
      if (item.id == courseID) {
        item.course.modules?.map((el) => {
          if (el.module.id == moduleID) {
            setModuleIDForQuize(el.id);
            el.module.quizzes?.map((data) => {
              setQuizzesID(data.id);
              if (data.quize.id == questionID) {
                setQuestionsLength(data.quize.questions);
                setQuestions(data.quize.questions);
                setQuizeTitle(data.quize.title);
              }
            });
          }
        });
      }
    });
  }, [myBoughtCourses, courseID, moduleID, questionID]);

  const handleNextQuestion = () => {
    const newQuestions = [...questions];
    newQuestions.splice(currentQuestionIndex, 1);
    setQuestions(newQuestions);
    if (currentQuestionIndex < newQuestions.length) {
      setCurrentQuestionIndex(currentQuestionIndex);
    } else {
      setCurrentQuestionIndex(0);
    }
  };

  if (result?.length === questionsLength.length) {
    navigate("/result");
  }

  const handleSkipQuestion = () => {
    if (currentQuestionIndex <= questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
    if (currentQuestionIndex == questions?.length - 1) {
      setCurrentQuestionIndex(0);
    }
  };

  return (
    <>
      <div>
        <div className="pl-[10px]">
          <Breadcrumb
            items={[
              {
                title: (
                  <NavLink to="/myCourses" className="no-underline">
                    Мої курси
                  </NavLink>
                ),
              },
              {
                title: <span>{quizeTitle ? quizeTitle : ""}</span>,
              },
            ]}
          />
        </div>
        <hr />
      </div>
      {quizeTitle ? (
        <div className=" flex flex-row justify-between">
          <div className="fake-div"></div>
          <div className="question-title">
            <span className="2xl:text-3xl xl:text-2xl lg:text-xl">
              {quizeTitle}
            </span>
          </div>
          <div className="2xl:text-2xl xl:text-xl  lg:text-lg 2xl:pr-[10px] xl:pr-[5px] lg:pr-[2px] w-[100px]">
            <Timer initialTimeInSeconds={2 * 60} />
          </div>
        </div>
      ) : null}
      {questions ? (
        <QuestionData
          key={quizzesID}
          questionID={quizzesID}
          returnID={moduleID}
          moduleID={moduleID}
          courseID={courseID}
          moduleIDForQuize={moduleIDForQuize}
          arrayQuestions={questions}
          currentQuestionIndex={currentQuestionIndex}
          selectedAnswer={questions[currentQuestionIndex]?.selectedAnswer}
          answers={questions[currentQuestionIndex]?.answers}
          question={questions[currentQuestionIndex]?.question}
          handleNextQuestion={handleNextQuestion}
          handleSkipQuestion={handleSkipQuestion}
        />
      ) : (
        <></>
      )}
    </>
  );
};
