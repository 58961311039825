import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useState } from "react";
import "./styles/courses-module.css";

export const CoursesModule = (props) => {
  const { title, description, lessons } = props;
  const [showLesson, setShowLesson] = useState(false);

  const changeLesson = () => {
    setShowLesson((prev) => !showLesson);
  };

  return (
    <div className="pt-[10px]">
      <div className="flex flex-col">
        <div
          onClick={() => changeLesson()}
          className="flex flex-row justify-center border-1 border-stone-300 rounded-t-md py-[10px] cursor-pointer"
        >
          <div className="flex justify-between w-[90%] ">
            <div className="text-start">
              <div className="size-title">
                <span>{title}</span>
              </div>
              <div className="w-[98%]">
                <div className="size-info">
                  <span>{description}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[20px] h-[20px] rounded-full border-1 border-violet-700">
            {showLesson ? (
              <div className="-mt-[4px]">
                <DownOutlined />
              </div>
            ) : (
              <div className="-mt-[5px]">
                <UpOutlined />
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col justify-center  ">
          {showLesson ? (
            lessons.map((lesson, i) => (
              <div
                key={lesson.id}
                className="flex justify-center py-[5px] bg-sky-50 border-l border-r border-b border-stone-300 rounded-b-md cursor-not-allowed"
              >
                <div className="flex flex-col w-[80%] ">
                  <div className="flex flex-row justify-start">
                    <div className="size-title">{lesson.title}</div>
                  </div>
                  <div className="size-info">
                    <span>{lesson.description}</span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
