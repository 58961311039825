import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { MyCoursesItem } from "../../components/my-courses-item/my-courses-item";
import { boughtCoursesThunk } from "../../slice/my-courses/boughtCourses/boughtCoursesThunk";
import { Button, Spin } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import empty from "../../assets/empty.png";
import { Breadcrumb } from "antd";
import Cookies from "js-cookie";
import { fetchData } from "../../slice/courses/fetchData";
import { studentThunk } from "../../slice/student/studentThunk";
import "./styles/my-courses.css";

export const MyCourses = () => {
  const { isLoading, myBoughtCourses, error } = useSelector(
    (state) => state.boughtCoursesReducer
  );
  const myCourses = useSelector((state) => state.coursesReducer.myCourses);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  useEffect(() => {
    if (token) {
      dispatch(fetchData());
      dispatch(studentThunk());
    }
    if (!token) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (myCourses) {
        const { my_courses } = myCourses;
        dispatch(boughtCoursesThunk({ my_courses }));
      }
    }, 1000);
  }, [myCourses]);

  return (
    <div className="flex flex-col w-[100%] ">
      <div>
        <div className="pl-[10px]">
          <Breadcrumb
            items={[
              {
                title: "Мої курси",
              },
            ]}
          />
        </div>
        <hr />
      </div>
      <div className="2xl:py-[10px] md:py-[10px]">
        <h1 className="md:text-3xl">Мої курси</h1>
      </div>
      <>
        {isLoading && (
          <div className="flex justify-center w-[100%] pt-[10%] pb-[20px]">
            <Spin size="large" />
          </div>
        )}
        {myBoughtCourses &&
          (myBoughtCourses.length === 0 ? (
            <div className="flex justify-center w-[100%] ">
              <div className="flex flex-col justify-center w-[100%]">
                <div className="flex  justify-center w-[100%] ">
                  <img src={empty} alt="" className="size-empty-img" />
                </div>
                <h1 className="text-gray-500">Ви не купили курсів</h1>
                <div className="flex flex-row justify-center">
                  <span className="pr-[5px] pt-[5px]">Перейдіть щоб</span>
                  <NavLink to="/">
                    <Button type="primary" className="bg-sky-500 ">
                      Купити курс
                    </Button>
                  </NavLink>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex justify-center">
              <div className="size-my-courses">
                {myBoughtCourses?.map((item) => (
                  <MyCoursesItem
                    key={item.course.id}
                    title={item.course.title}
                    price={item.course.price}
                    proffesion={item.course.proffesion}
                    speaker={item.course.speaker}
                    courseID={item.id}
                    modules={item.course.modules}
                    description={item.course.description}
                    duration={item.course.duration}
                    image={item.course.image}
                  />
                ))}
              </div>
            </div>
          ))}
        {error && (
          <div className="flex justify-center w-[100%] pt-[10%]">
            <h4>{error}</h4>
          </div>
        )}
      </>
    </div>
  );
};
