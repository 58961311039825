import { Button, Progress } from "antd";
import { Card, CardBody } from "reactstrap";
import { NavLink } from "react-router-dom";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { MyCoursesModule } from "./my-coureses-module";
import "./styles/my-courses-item.css";

export const MyCoursesItem = (props) => {
  const { image, proffesion, duration, title, speaker, modules, courseID } =
    props;
  const [showModule, setShowModule] = useState(true);
  const [passedModule, setPassedModule] = useState([]);

  const [id, setID] = useState();

  const changeModule = () => {
    setShowModule((prev) => !showModule);
  };

  useEffect(() => {
    if (modules) {
      modules.map((item) => {
        setID(item.module.id);
      });
    }
  }, []);

  useEffect(() => {
    if (modules) {
      const passedArray = modules.map((item) => item.passed);
      setPassedModule(passedArray);
    }
  }, [modules]);

  const trueCount = passedModule?.reduce(
    (count, value) => count + (value === true ? 1 : 0),
    0
  );
  const totalCount = passedModule?.length;
  const percentageTrue = (trueCount / totalCount) * 100;

  return (
    <div className="flex justify-center w-[100%]" key={id}>
      <div className="w-[100%] pb-[10px]">
        <div className="2xl:py-[20px] md:py-[10px]">
          <Card className="hover:shadow-2xl hover:bg-sky-50">
            <CardBody>
              <div onClick={changeModule} className="my-main-block">
                <div className="my-fake-block"></div>
                <div className="my-center-block">
                  <div className="my-for-center-img">
                    <div className="my-courses-size-img">
                      <img src={image} alt="" className="rounded-md" />
                    </div>
                  </div>
                  <div className="my-width-on-laptop">
                    <div className="w-[30%]">
                      <img src={image} alt="" className="rounded-md" />
                    </div>
                  </div>
                  <div className="width-info">
                    <div className="my-course-info">
                      <div className="my-course-info-title">
                        <span className="2xl:text-3xl xl:text-2xl lg:text-xl font-medium">
                          {title}
                        </span>
                      </div>
                      <div className="flex flex-row justify-between w-[100%] 2xl:pt-[32px] lg:pt-[20px] text-start">
                        <div className="w-[37%]">
                          <span className="my-course-name">Курс:</span>
                        </div>
                        <div className="w-[63%]">
                          <div className="my-last-name-block">
                            <span className="my-course-last-name">{title}</span>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row justify-between w-[100%] text-start ">
                        <div className="w-[37%] border-t border-b border-stone-300">
                          <span className="my-course-name">Професiя:</span>
                        </div>
                        <div
                          className=" border-t border-b border-stone-300 w-[63%]"
                          key={proffesion?.id}
                        >
                          <div className="my-last-name-block">
                            <span className="my-course-last-name">
                              {proffesion?.title}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row justify-between w-[100%] text-start ">
                        <div className="font-medium border-b border-stone-300 w-[38%]">
                          <span className="my-course-name">Тривалість:</span>
                        </div>
                        <div className=" border-b border-stone-300 w-[63%]">
                          <div className="my-last-name-block">
                            <span className="my-course-last-name">
                              {duration}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row justify-between w-[100%] h-[50px]">
                        <div className="w-[37%] text-start">
                          <span className="my-course-name ">Спікер :</span>
                        </div>
                        <div className=" w-[63%]">
                          <div className="my-last-name-block">
                            <span className="my-course-last-name">
                              {speaker?.name}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="progress-line">
                        <Progress
                          percent={passedModule ? percentageTrue : 0}
                          strokeColor={
                            percentageTrue == 100
                              ? { color: "#19e619" }
                              : { color: "#6836D3" }
                          }
                          status="active"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="my-progress">
                  <div className="progress-block">
                    <div className="2xl:pt-[70px] md:pt-[40px] 2xl:pl-[25%] lg:pl-[0]">
                      <Progress
                        type="circle"
                        percent={passedModule ? percentageTrue : 0}
                        size={160}
                        strokeColor={
                          percentageTrue == 100
                            ? { color: "#19e619" }
                            : { color: "#6836D3" }
                        }
                      />
                    </div>
                  </div>
                  <div className="my-switch-module">
                    {showModule ? (
                      <div className="mt-[1px]">
                        <DownOutlined />
                      </div>
                    ) : (
                      <div>
                        <UpOutlined />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="pt-[10px] flex justify-center">
                <NavLink to={`/lessonsData/${id}`}>
                  <Button className="bg-violet-700 text-white">
                    Почати курс
                  </Button>
                </NavLink>
              </div>
              <div className="pt-[5px]">
                {showModule
                  ? modules?.map((el) => (
                      <MyCoursesModule
                        title={el.module.title}
                        passed={el.module.passed}
                        description={el.module.description}
                        lessons={el.module.lessons}
                        quizzes={el.module.quizzes}
                        moduleID={el.module.id}
                        key={el.module.id}
                        courseID={courseID}
                      />
                    ))
                  : null}
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};
