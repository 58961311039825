import { useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./crop-image";

const aspectRations = [
  { value: 4 / 3, text: "4/3" },
  { value: 16 / 9, text: "16/9" },
  { value: 1 / 2, text: "1/2" },
];

export const ImageCropDialog = ({
  imageUrl,
  cropInit,
  zoomInit,
  aspectInit,
  onCansel,
  setCroppedImageFor,
  id,
}) => {
  if (zoomInit == null) {
    zoomInit = 1;
  }
  if (cropInit == null) {
    cropInit = { x: 0, y: 0 };
  }

  if (aspectInit == null) {
    aspectInit = aspectRations[0];
  }

  const [zoom, setZoom] = useState(zoomInit);
  const [crop, setCrop] = useState(cropInit);
  const [aspect, setAspect] = useState(aspectInit);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageObjectURL, setImageObjectURL] = useState(null);

  useEffect(() => {
    if (imageUrl) {
      const objectURL = URL.createObjectURL(imageUrl);
      setImageObjectURL(objectURL);
    }

    return () => {
      if (imageObjectURL) {
        URL.revokeObjectURL(imageObjectURL);
      }
    };
  }, []);

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };

  const onAspectChange = (e) => {
    const value = e.target.value;
    const ratio = aspectRations.find((ratio) => ratio.value == value);
    setAspect(ratio);
  };

  const onCropComplete = (croppedAre, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onCrop = async () => {
    const croppedImageUrl = await getCroppedImg(
      imageObjectURL,
      croppedAreaPixels
    );

    setCroppedImageFor(croppedImageUrl, id);
  };

  return (
    <div>
      <div className="position-fixed bg-black top-0 left-0 right-0 bottom-0 z-50">
        <Cropper
          image={imageObjectURL}
          zoom={zoom}
          crop={crop}
          aspect={aspect.value}
          onCropChange={onCropChange}
          onZoomChange={onZoomChange}
          onCropComplete={onCropComplete}
        />
      </div>
      <div className="position-fixed left-0 bottom-0 w-[100%] h-[80px] z-50">
        <div>
          <input
            type="range"
            min={1}
            max={3}
            step={0.1}
            value={zoom}
            onInput={(e) => {
              onZoomChange(e.target.value);
            }}
            className="w-[50%]"
          />
          <select onChange={onAspectChange}>
            {aspectRations.map((ratio) => (
              <option
                key={ratio.text}
                value={ratio.value}
                selected={ratio.value === aspect.value}
              >
                {ratio.text}
              </option>
            ))}
          </select>
          <div>
            <button
              className="bg-violet-800 text-white w-[100px]"
              onClick={(e) => {
                e.preventDefault();
                onCrop();
              }}
            >
              Зберегти
            </button>
            <button
              className="bg-red-500 text-white w-[100px]"
              onClick={(e) => {
                e.preventDefault();
                onCansel();
              }}
            >
              Відміна
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
