import axios from "axios";
import { studentFailure, studentStart, studentSuccess } from "./studentReducer";
import Cookies from "js-cookie";
import { GLOBAL_URL } from "../global-url/global-url";

export const studentThunk = () => {
  const token = Cookies.get("token");
  return async (dispatch) => {
    try {
      dispatch(studentStart());
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const res = await axios.get(
        `${GLOBAL_URL}/api/v1/student/student_profile/`,
        {
          headers,
        }
      );
      dispatch(studentSuccess(res.data));
    } catch (e) {
      dispatch(studentFailure(e.message));
    }
  };
};
