import { Button } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logoutSuccess } from "../../slice/auth/authReducer";
import { ProfileButton } from "./profileButton";
import { ArrowRightOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useState } from "react";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { Notice } from "./notice";
import { noticeThunk } from "../../slice/notice/noticeThunk";
import "./styles/header.css";

export const Header = (props) => {
  const { handleMenu, handleMenuMobile } = props;
  const dispatch = useDispatch();
  const [click, setClick] = useState(false);
  const { student } = useSelector((state) => state.studentReducer);

  useEffect(() => {
    dispatch(noticeThunk());
  }, []);

  const token = Cookies.get("token");

  const handleButtonClick = () => {
    Cookies.remove("token");
    dispatch(logoutSuccess());
    window.location.reload();
  };

  const handleCLick = () => {
    setClick((prev) => !click);
  };

  return (
    <div>
      <div className="w-[100%] flex flex-row justify-between">
        <div className="pl-[10px] pt-[10px]">
          <div className="click-nav-desktop">
            <button
              onClick={() => {
                handleMenu(), handleCLick();
              }}
              className="text-2xl text-blue-700"
            >
              {click ? <MenuUnfoldOutlined /> : <ArrowRightOutlined />}
            </button>
          </div>
          <div className="click-nav-mobile">
            <button
              onClick={() => {
                handleMenuMobile(), handleCLick();
              }}
              className="text-2xl text-blue-700"
            >
              <MenuUnfoldOutlined />
            </button>
          </div>
        </div>
        <div className="pr-[20px] pt-[10px] ">
          {token ? (
            <>
              <div className="flex flex-row">
                <div className="pt-[5px] cursor-pointer">
                  <Notice />
                </div>
                <div className="px-[20px]">
                  <span className="text-4xl text-blue-700">|</span>
                </div>
                <div>
                  <ProfileButton
                    student={student}
                    handleButtonClick={handleButtonClick}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <NavLink to="/register">
                <Button type="primary" className="mr-[10px] bg-violet-700">
                  Реєстрація
                </Button>
              </NavLink>
              <NavLink to="/login">
                <Button>Вхід</Button>
              </NavLink>
            </>
          )}
        </div>
      </div>
      <hr className="text-blue-700" />
    </div>
  );
};
