import { Button } from "antd";
import { useState } from "react";
import { Card, CardBody } from "reactstrap";
import { answersResult } from "../../slice/answers/answersReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const QuestionData = (props) => {
  const {
    answers,
    question,
    handleNextQuestion,
    questionID,
    moduleIDForQuize,
    courseID,
    returnID,
    handleSkipQuestion,
  } = props;

  const [activeIndex, setActiveIndex] = useState(-1);
  const [selectedAnswer, setSelectedAnswer] = useState(null); // Добавляем состояние для выбранного ответа
  const [formValid, setFormValid] = useState(false);
  const [getAnswer, setGetAnswer] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const styleAnswerA =
    "bg-violet-700 text-white text-start 2xl:w-[60%] xl:w-[90%] lg:w-[90%] py-[5px] pl-[10px] rounded-lg cursor-pointer";
  const styleAnswerB = "text-start pl-[10px] py-[5px] cursor-pointer";

  const handleChoice = (index) => {
    setActiveIndex(index);
    setSelectedAnswer(answers[index]);
    setFormValid(true);
  };

  const sendAnswer = () => {
    if (getAnswer && activeIndex > -1) {
      dispatch(
        answersResult({
          getAnswer,
          questionID,
          moduleID: moduleIDForQuize,
          courseID,
          returnID,
        })
      );
      setGetAnswer("");
      setActiveIndex(-1);
      setFormValid(false);
      handleNextQuestion();
    }
  };

  if (!answers && !question) return null;

  return (
    <div className="w-[100%] 2xl:pt-[20px] sm:pt-[10px] pb-[10px] flex justify-center ">
      <Card className="size-question">
        <CardBody>
          <div className="2xl:text-2xl lg:text-lg font-medium">
            <span>{question}</span>
          </div>
          <div className=" xl:py-[40px] md:py-[20px]">
            {answers?.map((item, index) => (
              <div className="w-[100%] pt-[10px]" key={item.id}>
                <div
                  className={
                    index === activeIndex ? styleAnswerA : styleAnswerB
                  }
                  onClick={() => {
                    handleChoice(index), setGetAnswer(item);
                  }}
                >
                  {item.answer}
                </div>
              </div>
            ))}
          </div>
          <div className="pt-[40px]">
            <Button
              type="primary"
              className="bg-violet-700"
              disabled={!formValid}
              onClick={() => sendAnswer()}
            >
              Вибрати
            </Button>{" "}
            <Button
              type="primary"
              className="bg-violet-700"
              onClick={handleSkipQuestion}
            >
              Пропустити
            </Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
