import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  image: null,
  error: null,
};

export const imageReducer = createSlice({
  name: "image",
  initialState,
  reducers: {
    imageStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    imageSuccess: (state, action) => ({
      ...state,
      image: action.payload,
      isLoading: false,
      error: null,
    }),
    imageFailure: (state, action) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
  },
});

export const { imageStart, imageSuccess, imageFailure } = imageReducer.actions;
export default imageReducer.reducer;
