import { FieldTimeOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";

export const Timer = ({ initialTimeInSeconds }) => {
  const [seconds, setSeconds] = useState(initialTimeInSeconds);
  const [isRunning, setIsRunning] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    let intervalId;

    // Функция обновления времени таймера
    const updateTimer = () => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        } else {
          setIsRunning(false);
          return initialTimeInSeconds; // Обновляем время до исходного значения после достижения нуля
        }
      });
    };

    // Установка интервала обновления таймера на 1 секунду, только если таймер запущен
    if (isRunning && seconds > 0) {
      intervalId = setInterval(updateTimer, 1000);
    }

    // Очистка интервала при размонтировании компонента
    return () => clearInterval(intervalId);
  }, [isRunning, seconds, initialTimeInSeconds]);

  // Функция для форматирования времени в формат "минуты:секунды"
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  // Функция для старта или паузы таймера
  const handleStartPause = () => {
    setIsRunning((prevIsRunning) => !prevIsRunning);
  };

  // Функция для сброса таймера
  const handleReset = () => {
    setSeconds(initialTimeInSeconds);
    setIsRunning(true); // Запускаем таймер сразу после сброса
  };

  useEffect(() => {
    if (seconds === 0) {
      navigate("/result");
    }
  }, [seconds, navigate]);

  return (
    <div className="flex flex-row">
      <div className="-mt-[3px]">
        <FieldTimeOutlined />
      </div>
      <p className="pl-[5px] text-green-500">{formatTime(seconds)}</p>
    </div>
  );
};
