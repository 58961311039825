import React from "react";
import { Button } from "antd";
import { MessageOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";

export const FAQ = () => {
  return (
    <>
      <NavLink to="https://t.me/trojan_NZ_bot" target="_blank">
        <Button
          type="primary"
          className="bg-violet-700 w-[70px] h-[70px] rounded-full"
        >
          <div className="text-4xl mb-[7px]">
            <MessageOutlined />
          </div>
        </Button>
      </NavLink>
    </>
  );
};
