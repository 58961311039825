import { useDispatch, useSelector } from "react-redux";
import { CertificatesItem } from "../../components/certificates-item/certificates-item";
import { Card, CardBody } from "reactstrap";
import { Spin } from "antd";
import empty from "../../assets/emptysert.png";
import { useEffect } from "react";
import { fetchData } from "../../slice/courses/fetchData";
import { studentThunk } from "../../slice/student/studentThunk";
import Cookies from "js-cookie";
import { useNavigate } from "react-router";
import "./styles/certificates.css";

export const Certificates = () => {
  const { isLoading, student, error } = useSelector(
    (state) => state.studentReducer
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  useEffect(() => {
    if (token) {
      dispatch(fetchData());
      dispatch(studentThunk());
    }
    if (!token) {
      navigate("/");
    }
  }, []);

  return (
    <div className="flex justify-center">
      <div className="2xl:w-[92%] md:w-[74%]">
        <Card className="shadow-2xl">
          <CardBody>
            <h1 className="md:text-2xl">Сертифікати</h1>
            {isLoading && (
              <div className="pt-[10%] pb-[20px]">
                <Spin size="large" />
              </div>
            )}
            {student &&
              student.map((item) =>
                item.my_certificate.length > 0 ? (
                  item.my_certificate.map((el) => (
                    <CertificatesItem
                      certificate={el.certificate}
                      date={el.date}
                      name={el.name}
                      student={el.student}
                      id={el.id}
                      key={el.id}
                    />
                  ))
                ) : (
                  <div className="empty-data">
                    <div className="center">
                      <div>
                        <img src={empty} alt="" className="w-[400px]" />
                      </div>
                      <div className="space-text">
                        <span className="text-gray-500">
                          Ви ще не пройшли жодного курсу
                        </span>
                      </div>
                    </div>
                  </div>
                )
              )}
            {error && (
              <div>
                <h1>{error}</h1>
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};
