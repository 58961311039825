import axios from "axios";
import Cookies from "js-cookie";
import { noticeFailure, noticeStart, noticeSuccess } from "./noticeReducer";
import { GLOBAL_URL } from "../global-url/global-url";

export const noticeThunk = () => {
  const token = Cookies.get("token");

  return async (dispatch) => {
    try {
      dispatch(noticeStart());
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(`${GLOBAL_URL}/api/v1/commun/news/`, {
        headers,
      });
      dispatch(noticeSuccess(res.data));
    } catch (e) {
      dispatch(noticeFailure(e.message));
    }
  };
};
