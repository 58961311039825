import React from "react";

export const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col text-start pl-[20px]">
      <h1>Політика конфіденційності</h1>
      <p>Останнє оновлення: 02.10.2023</p>

      <p>
        Ваша конфіденційність надзвичайно важлива для нас. У цій політиці
        конфіденційності описано, як ми збираємо, використовуємо, передаємо та
        захищаємо вашу особисту інформацію у зв'язку з використанням нашого
        додатка Trojan Education.
      </p>

      <h2>1. Збір інформації</h2>

      <h3>1.1. Інформація, надана вами</h3>
      <p>
        Ми можемо збирати інформацію, яку ви надаєте нам при використанні нашого
        додатка, включаючи, але не обмежуючись:
      </p>
      <ul>
        <li>Ваше ім'я та контактна інформація</li>
        <li>Інформацію, яку ви надаєте при реєстрації у додатку</li>
        <li>Повідомлення та зворотний зв'язок, надіслані вами через додаток</li>
        <li>Інші дані, які ви надаєте нам під час використання додатка</li>
      </ul>

      <h3>1.2. Інформація про використання додатка</h3>
      <p>
        Ми можемо збирати інформацію про використання нашого додатка, включаючи,
        але не обмежуючись:
      </p>
      <ul>
        <li>
          Інформацію про ваш пристрій, включаючи модель, операційну систему та
          унікальний ідентифікатор пристрою
        </li>
        <li>
          Інформацію про ваше взаємодію з додатком, включаючи дату та час
          використання, тривалість сеансу, функції та можливості, до яких ви
          звертаєтеся, та інші статистичні дані
        </li>
      </ul>

      <h2>2. Використання інформації</h2>
      <p>Ми використовуємо зібрану інформацію для наступних цілей:</p>
      <ul>
        <li>Забезпечення роботи та надання вам доступу до нашого додатка</li>
        <li>Підтримка та обслуговування додатка</li>
        <li>Аналіз використання додатка та покращення його функціональності</li>
        <li>Надання вам персоналізованих послуг та рекомендацій</li>
        <li>
          Розміщення оголошень та маркетингових повідомлень, якщо ви погодилися
          на їх отримання
        </li>
      </ul>

      <h2>3. Розголошення інформації</h2>
      <p>Ми можемо розголошувати вашу особисту інформацію наступним чином:</p>
      <ul>
        <li>
          З третіми сторонами, з якими у нас є угода про надання послуг або
          співпрацю, в межах виконання цих угод.
        </li>
        <li>
          Якщо це вимагається законом або в зв'язку з юридичними процедурами,
          включаючи відповіді на запити правоохоронних органів.
        </li>
      </ul>

      <h2>4. Захист даних</h2>
      <p>
        Ми приймаємо розумні заходи для забезпечення безпеки вашої особистої
        інформації та захисту її від несанкціонованого доступу, використання або
        розголошення.
      </p>

      <h2>5. Ваші права</h2>
      <p>Ви маєте певні права щодо вашої особистої інформації, включаючи:</p>
      <ul>
        <li>Право на доступ до вашої особистої інформації</li>
        <li>Право на виправлення невірної або застарілої інформації</li>
        <li>Право на видалення вашої особистої інформації</li>
        <li>Право на обмеження обробки вашої особистої інформації</li>
        <li>Право на переносимість даних</li>
      </ul>

      <h2>6. Зв'язок з нами</h2>
      <p>
        Якщо у вас є запитання, пропозиції або скарги стосовно нашої політики
        конфіденційності або обробки ваших даних, будь ласка, зв'яжіться з нами
        за адресою [ваш контактний адреса].
      </p>

      <h2>7. Зміни в політиці конфіденційності</h2>
      <p>
        Ми можемо час від часу оновлювати нашу політику конфіденційності.
        Будь-які зміни будуть розміщені на цій сторінці з вказівкою дати
        останнього оновлення.
      </p>

      <p>Дата останнього оновлення: 02.10.2023</p>
    </div>
  );
};
