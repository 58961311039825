import axios from "axios";
import { imageFailure, imageStart, imageSuccess } from "./imageReducer";
import Cookies from "js-cookie";
import { GLOBAL_URL } from "../global-url/global-url";

export const changeImageThunk = (props) => {
  const { newImage, id } = props;

  let formData = new FormData();
  formData.append("image", newImage.image);

  const token = Cookies.get("token");

  return async (dispatch) => {
    try {
      dispatch(imageStart());
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.put(
        `${GLOBAL_URL}/api/v1/student/student_profile/${id}/image/`,
        formData,
        {
          headers,
        }
      );
      dispatch(imageSuccess(res.data));
    } catch (e) {
      dispatch(imageFailure(e.message));
    }
  };
};
