import {
  DownOutlined,
  UpOutlined,
  PlayCircleOutlined,
  FolderOpenOutlined,
  FileTextOutlined,
  QuestionOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import "./styles/my-courses-module.css";

export const MyCoursesModule = (props) => {
  const { title, description, lessons, moduleID, quizzes, courseID } = props;
  const [showLesson, setShowLesson] = useState(false);

  const changeLesson = () => {
    setShowLesson((prev) => !showLesson);
  };

  return (
    <div className="pt-[10px]">
      <div className="flex flex-col">
        <div onClick={() => changeLesson()} className="my-courses-module">
          <div className="flex justify-between md:w-[90%]">
            <div>
              <div className="py-[10px] md:text-xl sm:text-xs font-medium">
                <span>{title}</span>
              </div>
              <div className="text-start w-[98%] md:text-sm sm:text-xs 2xl:text-base pl-[5px]">
                <span>{description}</span>
              </div>
            </div>
          </div>
          <div className="my-courses-show-lesson">
            <div className="w-[20px] h-[20px] rounded-full border-1 border-violet-700">
              {showLesson ? (
                <div className="-mt-[4px]">
                  <DownOutlined />
                </div>
              ) : (
                <div className="-mt-[5px]">
                  <UpOutlined />
                </div>
              )}
            </div>
          </div>
          <div className="my-courses-show-lesson-mobile">
            <div className="w-[20px] h-[20px] rounded-full border-1 border-violet-700">
              {showLesson ? (
                <div className="-mt-[4px]">
                  <DownOutlined />
                </div>
              ) : (
                <div className="-mt-[5px]">
                  <UpOutlined />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center  ">
          {showLesson
            ? lessons.map((item, i) => {
                let lessonID = item.lesson.id;
                const number = 1;
                return (
                  <div
                    key={item.lesson.id}
                    className="flex justify-center py-[15px] border-l border-r border-b border-stone-300 cursor-pointer"
                  >
                    <div className="my-courses-lesson">
                      <div className="flex flex-row ">
                        <div className="w-[10px] pt-[4px]">{i + 1}</div>
                        <div className="pl-[10px] font-medium pt-[4px] md:text-xl sm:text-xs">
                          {item.lesson.title}
                        </div>
                      </div>
                      <div className="my-courses-lesson-icon">
                        <NavLink
                          to={`/lessonsData/${moduleID}/${lessonID}/${number}`}
                        >
                          <div className="text-2xl -mt-[6px]">
                            <PlayCircleOutlined />
                          </div>
                        </NavLink>
                        <NavLink
                          to={`/lessonsData/${moduleID}/${lessonID}/${
                            number + 1
                          }`}
                        >
                          <div className="text-2xl -mt-[6px]">
                            <FolderOpenOutlined />
                          </div>
                        </NavLink>
                        <NavLink
                          to={`/lessonsData/${moduleID}/${lessonID}/${
                            number + 2
                          }`}
                        >
                          <div className="text-2xl -mt-[6px]">
                            <QuestionOutlined />
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
          {showLesson
            ? quizzes.map((el) => {
                let questionID = el.quize.id;
                return (
                  <div
                    key={questionID}
                    className="flex justify-center py-[15px] border-l border-r border-b rounded-b-md border-stone-300 cursor-pointer"
                  >
                    <div className=" w-[80%] ">
                      <div className="quize-lesson">
                        <div className="quize-title">{el.quize.title}</div>
                        <div className="spase-test">
                          <NavLink
                            to={`/question/${courseID}/${moduleID}/${questionID}`}
                          >
                            <Button c>
                              <div className="flex flex-row">
                                <div className="-mt-[3px]">
                                  <FileTextOutlined />
                                </div>
                                <div className="pl-[5px]">Розпочати</div>
                              </div>
                            </Button>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};
