import React from "react";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space, Divider, Button, theme } from "antd";
import { NavLink } from "react-router-dom";
import user from "../../assets/user.png";

import {
  UserOutlined,
  FileDoneOutlined,
  BarsOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";

const { useToken } = theme;

const items = [
  {
    key: "1",
    label: (
      <NavLink to="/profile" className="flex flex-row no-underline">
        <div>
          <UserOutlined className="w-[5px] h-[3px] " />
        </div>
        <div className="pl-[15px] pt-[3px]">Профіль</div>
      </NavLink>
    ),
  },
  {
    key: "2",
    label: (
      <NavLink to="/myCourses" className="flex flex-row no-underline">
        <div>
          <BarsOutlined className="w-[5px] h-[3px] " />
        </div>
        <div className="pl-[15px] pt-[3px]">Мої курси</div>
      </NavLink>
    ),
  },
  {
    key: "3",
    label: (
      <NavLink to="/certificates" className="flex flex-row no-underline">
        <div>
          <FileDoneOutlined className="w-[5px] h-[3px] " />
        </div>
        <div className="pl-[15px] pt-[3px]">Сертифікати</div>
      </NavLink>
    ),
  },
];

export const ProfileButton = (props) => {
  const { handleButtonClick, student } = props;
  const { token } = useToken();
  const changedImage = useSelector((state) => state.imageReducer.image);

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle = {
    boxShadow: "none",
  };

  return (
    <Dropdown
      menu={{ items }}
      dropdownRender={(menu) => (
        <div style={contentStyle}>
          {React.cloneElement(menu, { style: menuStyle })}
          <Divider style={{ margin: 0 }} />
          <Space style={{ padding: 8 }}>
            <Button
              onClick={handleButtonClick}
              type="primary"
              className="bg-violet-700"
            >
              Вийти
            </Button>
          </Space>
        </div>
      )}
    >
      <div onClick={(e) => e.preventDefault()}>
        <Space className="w-[170px] h-[40px] cursor-pointer">
          {student &&
            student.map((item) =>
              changedImage ? (
                <img
                  src={changedImage.image ? changedImage.image : user}
                  alt=""
                  className="img-thumbnail rounded-circle"
                  style={{ width: "40px", height: "40px" }}
                />
              ) : (
                <img
                  key={item.id}
                  src={item.image ? item.image : user}
                  alt=""
                  className="img-thumbnail rounded-circle"
                  style={{ width: "40px", height: "40px" }}
                />
              )
            )}
          <span>Мій кабінет</span>
          <DownOutlined />
        </Space>
      </div>
    </Dropdown>
  );
};
