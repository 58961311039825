import { Button } from "antd";
import { Card, CardBody } from "reactstrap";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useState } from "react";
import { CoursesModule } from "./coursesModule";
import "./styles/course.css";

export const CourseInfo = (props) => {
  const { image, proffesion, duration, title, price, id, speaker, modules } =
    props;
  const student = useSelector((state) => state.studentReducer.student);
  const [showModule, setShowModule] = useState(false);

  const start = "https://secure.wayforpay.com/button/b2c2fd7bff3d1";
  const pro = "https://secure.wayforpay.com/button/b7a48d02644dd";

  const changeModule = () => {
    setShowModule((prev) => !showModule);
  };

  return (
    <div className="flex justify-center w-[100%]" key={id}>
      <div className="w-[90%] pb-[10px]">
        <div className="2xl:py-[20px] md:py-[10px]">
          <Card className="hover:shadow-2xl hover:bg-sky-50">
            <CardBody>
              <div onClick={changeModule} className="main-block">
                <div className="fake-block"></div>
                <div className="center-block">
                  <div className="for-center-img">
                    <div className="img-size">
                      <img src={image} alt="" className="rounded-md" />
                    </div>
                  </div>
                  <div className="width-on-laptop">
                    <div className="img-size">
                      <img src={image} alt="" className="rounded-md" />
                    </div>
                  </div>

                  <div className="course-info">
                    <div className="course-info-title-block">
                      <div className="title">
                        <span className="2xl:text-3xl xl:text-2xl lg:text-xl font-medium">
                          {title}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between w-[100%] 2xl:pt-[33px] xl:pt-[15px]">
                      <div className=" border-b border-stone-300 w-[48%] text-start">
                        <span className="name">Спікер :</span>
                      </div>
                      <div className=" border-b border-stone-300 w-[52%] lg:pb-[5px] sm:pb-[0px] ">
                        <div className="last-name-block">
                          <span className="last-name">{speaker?.name}</span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between w-[100%]  text-start">
                      <div className="w-[48%]">
                        <span className="name">Курс:</span>
                      </div>

                      <div className="w-[52%]">
                        <div className="last-name-block">
                          <span className="last-name">{title}</span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between w-[100%] text-start ">
                      <div className="border-b border-t border-stone-300 w-[48%]">
                        <span className="name">Тривалість:</span>
                      </div>
                      <div className=" border-b border-t border-stone-300 w-[52%] ">
                        <div className="last-name-block">
                          <span className="last-name">{duration}</span>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-row justify-between w-[100%]  text-start">
                      <div className="w-[48%]">
                        <span className="name">Ціна:</span>
                      </div>
                      <div className="w-[52%]">
                        <div className="last-name-block">
                          <span className="last-name">{price} грн.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="switch-module">
                  {showModule ? (
                    <div className="switch-module-svg">
                      <DownOutlined />
                    </div>
                  ) : (
                    <div className="switch-module-svg">
                      <UpOutlined />
                    </div>
                  )}
                </div>
              </div>
              <div className="pt-[10px] flex justify-center">
                {student ? (
                  <NavLink to={id === 1 ? start : pro} target="_blank">
                    <Button
                      type="primary"
                      className="animate-pulse bg-violet-700"
                    >
                      Придбати
                    </Button>
                  </NavLink>
                ) : (
                  <NavLink to="/login">
                    <Button type="primary" className="bg-violet-700">
                      Придбати
                    </Button>
                  </NavLink>
                )}
              </div>
              <div className="pt-[5px]">
                {showModule
                  ? modules?.map((el) => (
                      <CoursesModule
                        title={el.title}
                        description={el.description}
                        lessons={el.lessons}
                        key={el.id}
                      />
                    ))
                  : null}
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};
