import React from "react";
import { useGetCourseQuery } from "../../slice/resApi/thunk";
import { useDispatch } from "react-redux";
import { fetchData } from "../../slice/courses/fetchData";
import { studentThunk } from "../../slice/student/studentThunk";
import { Spin } from "antd";
import { useEffect } from "react";
import { CourseInfo } from "../../components/course-item/course-info";
import Cookies from "js-cookie";

const Course = () => {
  const { data } = useGetCourseQuery();
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  useEffect(() => {
    if (token) {
      dispatch(fetchData());
      dispatch(studentThunk());
    }
  }, []);

  return (
    <div className="flex justify-center">
      <div className="2xl:w-[80%] md:w-[90%]">
        {data ? (
          data?.map((item) => (
            <CourseInfo
              key={item.id}
              title={item.title}
              price={item.price}
              proffesion={item.proffesion}
              speaker={item.speaker}
              id={item.id}
              modules={item.modules}
              description={item.description}
              duration={item.duration}
              image={item.image}
            />
          ))
        ) : (
          <div className="flex justify-center w-[100%] pt-[10%] pb-[20px]">
            <Spin size="large" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Course;
