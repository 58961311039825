import axios from "axios";
import { studentFailure, studentStart, studentSuccess } from "./studentReducer";
import Cookies from "js-cookie";
import { GLOBAL_URL } from "../global-url/global-url";

export const changeDataThunk = (props) => {
  const { datas, id } = props;

  let formData = new FormData();
  formData.append("first_name", datas.first_name);
  formData.append("last_name", datas.last_name);
  formData.append("phone_number", datas.phone_number);
  formData.append("telegram", datas.telegram);

  const token = Cookies.get("token");

  return async (dispatch) => {
    try {
      dispatch(studentStart());
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.put(
        `${GLOBAL_URL}/api/v1/student/student_profile/${id}/`,
        formData,
        {
          headers,
        }
      );
      let array = [res.data];
      dispatch(studentSuccess(array));
    } catch (e) {
      dispatch(studentFailure(e.message));
    }
  };
};
