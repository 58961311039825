import { useState } from "react";
import { MyLessonsDropdown } from "./my-lessons-dropdown";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { MyTestsDropdown } from "./my-tests-dropdown";

export const MyModulesDropdown = ({
  modulesTitle,
  modulesLessons,
  moduleID,
  courseID,
  moduleTests,
}) => {
  const [showMyLessons, setShowMyLessons] = useState(false);

  const myLessons = () => {
    setShowMyLessons((prev) => !showMyLessons);
  };

  const title = modulesTitle.substring(0, 6);

  return (
    <>
      <div className="flex flex-col pl-[10px]">
        <div className="flex flex-row cursor-pointer" onClick={myLessons}>
          <span className={showMyLessons ? "text-violet-700" : "text-black"}>
            - {title}
          </span>
          <div className="pl-[5px] text-xs pt-[3px]">
            {showMyLessons ? <DownOutlined /> : <UpOutlined />}
          </div>
        </div>
        {showMyLessons
          ? modulesLessons?.map((data) => (
              <MyLessonsDropdown
                lessonTitle={data.lesson.title}
                moduleID={moduleID}
                lessonID={data.lesson.id}
                key={data.lesson.id}
              />
            ))
          : null}
        {showMyLessons
          ? moduleTests?.map((el) => (
              <MyTestsDropdown
                questionID={el.quize.id}
                courseID={courseID}
                moduleID={moduleID}
              />
            ))
          : null}
      </div>
    </>
  );
};
