import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  notes: null,
  error: null,
};

export const notesReducer = createSlice({
  name: "notes",
  initialState,
  reducers: {
    notesStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    notesSuccess: (state, action) => ({
      ...state,
      notes: action.payload,
      isLoading: false,
      error: null,
    }),
    notesFailure: (state, action) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
  },
});

export const { notesStart, notesSuccess, notesFailure } = notesReducer.actions;
export default notesReducer.reducer;
