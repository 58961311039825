import { Alert, Calendar } from "antd";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchData } from "../../slice/courses/fetchData";
import { studentThunk } from "../../slice/student/studentThunk";

export const Calendars = () => {
  const [value, setValue] = useState(() => dayjs("2023-06-25"));
  const [selectedValue, setSelectedValue] = useState(() => dayjs("2023-06-25"));
  const onSelect = (newValue) => {
    setValue(newValue);
    setSelectedValue(newValue);
  };
  const onPanelChange = (newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();
  const token = Cookies.get("token");

  useEffect(() => {
    if (token) {
      dispatch(fetchData());
      dispatch(studentThunk());
    }
  }, []);

  // bitbucket

  return (
    <div className="flex pt-[20px] justify-center w-[100%]">
      <div className="w-[90%]">
        <div className="flex flex-col">
          <Alert
            message={`You selected date: ${selectedValue?.format(
              "YYYY-MM-DD"
            )}`}
          />
          <Calendar
            value={value}
            onSelect={onSelect}
            onPanelChange={onPanelChange}
          />
        </div>
      </div>
    </div>
  );
};
