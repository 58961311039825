import { Formik, Form, ErrorMessage, Field } from "formik";
import { resetPassworSchema } from "../../schema";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { resetThunk } from "../../slice/auth/resetThunk";
import { Alert } from "antd";
import "./styles/resetPassword.css";

export const ResetPassword = () => {
  const changePassword = useSelector((state) => state.resetReducer.data);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uidb64, token } = useParams();

  useEffect(() => {
    if (changePassword) {
      setTimeout(() => {
        navigate("/login");
      }, 500);
    }
  }, [changePassword]);

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const handleFormSubmit = (values) => {
    if (values.password == values.confirmPassword) {
      dispatch(resetThunk({ password: values.password, uidb64, token }));
    }
  };

  return (
    <div className="flex flex-col justify-center">
      {changePassword ? (
        <div className="flex justify-center">
          <div className="w-[80%]">
            <Alert
              message="Пароль успішно змінено"
              type="success"
              showIcon
              closable
            />
          </div>
        </div>
      ) : null}
      <div className="w-[100%] flex justify-center pt-[10%]">
        <div className="size-password">
          <div className="shadow-2xl rounded-lg border border-blue-700">
            <Formik
              initialValues={initialValues}
              validationSchema={resetPassworSchema}
              onSubmit={() => {}}
            >
              {({ values }) => (
                <Form className="py-[20px] ">
                  <div className="text-3xl flex justify-center text-black">
                    <span className="md:text-3xl 2xl:text-3xl">
                      Зміна пароля
                    </span>
                  </div>
                  <div className="pt-[20px] pb-[10px]">
                    <div className="flex justify-center">
                      <div className="flex flex-col w-[90%]">
                        <div className="flex flex-row justify-between w-[100%]">
                          <label>Пароль</label>
                        </div>

                        <div>
                          <Field
                            name="password"
                            type="password"
                            placeholder="Введіть свій пароль"
                            className={
                              <ErrorMessage name="password" /> ? (
                                "border-1 border-violet-700 rounded-md w-[100%]"
                              ) : (
                                "border-1 bg-violet-700 rounded-md w-[100%]"
                              )
                            }
                            autoComplete="yes"
                          />
                          <div className="text-start">
                            <ErrorMessage
                              name="password"
                              component="span"
                              className="text-red-400 text-sm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pt-[10px] pb-[10px]">
                    <div className="flex justify-center">
                      <div className="flex flex-col w-[90%]">
                        <div className="flex flex-row justify-between w-[100%]">
                          <label>Повторіть пароль</label>
                        </div>

                        <div>
                          <Field
                            name="confirmPassword"
                            type="password"
                            placeholder="Введіть свій пароль"
                            className={
                              <ErrorMessage name="password" /> ? (
                                "border-1 border-violet-700 rounded-md w-[100%]"
                              ) : (
                                "border-1 border-violet-700 rounded-md w-[100%]"
                              )
                            }
                            autoComplete="yes"
                          />
                          <div className="text-start">
                            <ErrorMessage
                              name="confirmPassword"
                              component="span"
                              className="text-red-400 text-sm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-center">
                    <div className="pt-[20px] w-[90%]">
                      <button
                        type="button"
                        onClick={() => handleFormSubmit(values)}
                        className="bg-violet-700 text-white w-[100%] h-[30px]"
                      >
                        Змінити
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};
