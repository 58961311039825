import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  answers: [],
};

export const answersReducer = createSlice({
  name: "answers",
  initialState,
  reducers: {
    answersResult: (state, action) => {
      const answerExists = state.answers.some(
        (item) => item.getAnswer.id === action.payload.getAnswer.id
      );

      if (!answerExists) {
        state.answers.push(action.payload);
      }
    },
    answersClear: (state) => {
      state.answers = [];
    },
  },
});

export const { answersResult, answersClear } = answersReducer.actions;
export default answersReducer.reducer;
