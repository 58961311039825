import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { courseApi } from "./resApi/thunk";
import authReducer from "./auth/authReducer";
import coursesReducer from "./courses/coursesReducer";
import boughtCoursesReducer from "./my-courses/boughtCourses/boughtCourseReducer";
import studentReducer from "./student/studentReducer";
import answersReducer from "./answers/answersReducer";
import registerReducer from "./auth/registerReducer";
import imageReducer from "./student/imageReducer";
import notesReducer from "./notes/notesReducer";
import resetReducer from "./auth/resetReducer";
import forgotReducer from "./auth/forgotReducer";
import noticeReducer from "./notice/noticeReducer";

const RootReducer = combineReducers({
  authReducer,
  coursesReducer,
  boughtCoursesReducer,
  studentReducer,
  answersReducer,
  registerReducer,
  imageReducer,
  notesReducer,
  resetReducer,
  forgotReducer,
  noticeReducer,
  [courseApi.reducerPath]: courseApi.reducer,
});

export const store = configureStore({
  reducer: RootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(courseApi.middleware),
});
