import { useDispatch, useSelector } from "react-redux";
import { CourseCompletion } from "./course-completion";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Spin } from "antd";
import { Breadcrumb } from "antd";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import { fetchData } from "../../slice/courses/fetchData";
import { studentThunk } from "../../slice/student/studentThunk";
import { boughtCoursesThunk } from "../../slice/my-courses/boughtCourses/boughtCoursesThunk";

export const LessonsData = () => {
  const { moduleID, lessonID, number } = useParams();
  const [myLessons, setMyLessons] = useState(null);
  const [ID, setID] = useState();
  const [courseID, setCourseID] = useState();
  const [moduleIDForQuize, setModuleIDForQuize] = useState();
  const [lessonTitle, setLessonTitle] = useState();
  const [lessonsID, setLessonsID] = useState(null);

  const { isLoading, myBoughtCourses, error } = useSelector(
    (state) => state.boughtCoursesReducer
  );
  const myCourses = useSelector((state) => state.coursesReducer.myCourses);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  useEffect(() => {
    if (token) {
      dispatch(fetchData());
      dispatch(studentThunk());
    }
    if (!token) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (myCourses) {
        const { my_courses } = myCourses;
        dispatch(boughtCoursesThunk({ my_courses }));
      }
    }, 1000);
  }, [myCourses]);

  useEffect(() => {
    if (myBoughtCourses) {
      myBoughtCourses.map((item) => {
        item.course.modules.map((el) => {
          if (el.module.id == moduleID) {
            setCourseID(item.id);
            setModuleIDForQuize(el.id);
            setID(el.module.id);
            setMyLessons(el.module.lessons);
            el.module.lessons.map((data) => {
              if (data.lesson.id == lessonID) {
                setLessonTitle(data.lesson.title);
                setLessonsID(data.id);
              }
            });
          }
        });
      });
    }
  }, [myBoughtCourses, lessonsID, moduleID]);

  return (
    <div className="flex flex-col w-[100%]">
      <div>
        <div className="pl-[10px]">
          <Breadcrumb
            items={[
              {
                title: (
                  <NavLink to="/myCourses" className="no-underline">
                    Мої курси
                  </NavLink>
                ),
              },
              {
                title: <span>{lessonTitle ? lessonTitle : ""}</span>,
              },
            ]}
          />
        </div>
        <hr />
      </div>
      {isLoading && (
        <div className="flex justify-center w-[100%] pt-[10%] pb-[20px]">
          <Spin size="large" />
        </div>
      )}

      {myLessons?.map((item) => {
        if (item.lesson.id == lessonID) {
          return (
            <CourseCompletion
              lessonID={item.id}
              courseID={courseID}
              id={item.lesson.id}
              text={item.lesson.description}
              material={item.lesson.material}
              title={item.lesson.title}
              video={item.lesson.video}
              questions={item.lesson.questions}
              note={item.note}
              passed={item.passed}
              number={number}
              key={item.lesson.id}
            />
          );
        }
      })}

      {error && (
        <div className="flex justify-center w-[100%] pt-[10%]">
          <h4>{error}</h4>
        </div>
      )}
    </div>
  );
};
